export const speakers = {
    methods: {
        speakers(speakers) {
            if (speakers && speakers.length) {
                let s = ''
                speakers.forEach(speaker => {
                    if (s) {
                        s += ', ' + speaker.name
                    } else {
                        s += speaker.name
                    }
                })
                return s
            }
        },
    },
}
