<template>
    <div class="sprints">
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->
        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head :page-head="pagehead"></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->
                <div class="page-contents">
                    <filters
                        @input="changeFilters"
                        @resetResults="resetResults"
                    />
                    <!-- upcoming-workshops -->
                    <div class="upcoming-workshops">
                        <workshop-list :workshops="workshops" />
                    </div>
                    <base-loader v-if="workshopsLoadding" />
                    <!-- upcoming-workshops -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/workshop-banner.jpg'
import imgLarge from '@/assets/images/banners/workshop-banner.jpg'
import imgBig from '@/assets/images/banners/workshop-banner.jpg'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { WorkshopHelper } from '@/common/crud-helpers/workshop'
import Filters from '@/modules/workshop/components/list/Filters.vue'
import WorkshopList from '@/modules/workshop/components/list/WorkshopList.vue'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        Filters,
        WorkshopList,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
                imgAlt: this.$t('Sprints'),
            },
            banner: '',
            pagehead: {
                title: '',
                subtitle: '',
            },
            filters: {
                page_size: 4,
            },
            status: '',
            workshopsLoadding: true,
            workshops: [],
        }
    },
    async created() {
        // const today = new Date()
        // const mmToday = today.getMonth() + 1
        // const ddToday = today.getDate() + 1
        // const yyToday = today.getFullYear()
        // this.filters.event_date_from = `${yyToday}-${mmToday}-${ddToday}`
        // this.getWorkshops()
        if (
            !this.dashboardSettings ||
            !this.dashboardSettings.dashboard_settings
        ) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_settings
        ) {
            this.setBannerAndTitle()
        }
    },
    computed: {
        ...mapGetters(['workshopList', 'dashboardSettings']),
    },
    methods: {
        getWorkshops() {
            this.workshopsLoadding = true
            WorkshopHelper.list(this.filters)
        },
        changeFilters(filters) {
            this.workshops = []
            this.filters.event_date_from = filters.event_date_from
            this.filters.event_date_to = filters.event_date_to
            this.status = filters.status
            this.filters.page = filters.page
            this.filters.ordering = filters.ordering
            this.filters.event_datetime_from = filters.event_datetime_from
            this.filters.event_datetime_to = filters.event_datetime_to
            WorkshopHelper.list(this.filters)
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.workshopsLoadding) {
                    let next = this.getNextPage(this.workshopList.next)
                    if (next) {
                        this.workshopsLoadding = true
                        this.filters.page = next
                        this.getWorkshops()
                    }
                }
            }
        },
        resetResults() {
            this.workshopsList = []
            this.workshops = []
        },
        setBannerAndTitle() {
            this.banner = this.dashboardSettings.dashboard_settings.sprint_banner_img
            this.pagehead.title = this.dashboardSettings.dashboard_settings[
                `sprint_title_${this.$i18n.locale}`
            ]
        },
    },
    mounted() {
        this.getNextData()
    },
    watch: {
        workshopList() {
            let allWorkshops = _.concat(
                this.workshops,
                this.workshopList.results,
            )
            this.workshops = cloneDeep(allWorkshops)
            this.workshopsLoadding = false
        },
        dashboardSettings() {
            this.setBannerAndTitle()
        },
    },
}
</script>

<style lang="scss" scoped>
.sprints {
    /deep/.page__head {
        min-height: 260px;
        @media screen and (max-width: 991px) {
            min-height: 260px;
        }
        @media screen and (max-width: 767px) {
            min-height: 230px;
        }
    }
}
/deep/.page-banner {
    --h: 560px;
    @media screen and (max-width: 767px) {
        --h: 560px;
    }
    &::after {
        background: $primary;
    }
}
/deep/.page__head {
    --pagetitle: #0ab5f2;
}
.content-wrapper {
    .page-contents {
        margin-top: 80px;
        padding-bottom: 170px;
        min-height: 470px;
        @media screen and (max-width: 991px) {
            // padding-top: 230px;
            padding-bottom: 70px;
        }
    }
}
.upcoming-workshops {
    padding-top: rem(35px);
}
</style>
