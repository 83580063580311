<template>
    <form class="filters">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <label class="control-label">{{ $t('Sprint') }}</label>
                    <v-select
                        :options="selectOptions"
                        valueProp="value"
                        textProp="text"
                        v-model="selectedStatus"
                    ></v-select>
                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <label class="control-label">{{ $t('From') }}</label>
                    <base-date-picker v-model="dateFrom" @input="checkFrom" />
                    <span v-if="errorFrom" class="error">{{
                        $t('This field should be less than To Field')
                    }}</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                <div class="form-group">
                    <label class="control-label">{{ $t('To') }}</label>
                    <base-date-picker v-model="dateTo" @input="checkTo" />
                    <span v-if="errorTo" class="error">{{
                        $t('This field should be more than From Field')
                    }}</span>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            selectedStatus: {
                text: this.$t('Nothing selected'),
                value: '',
            },
            dateFrom: null,
            dateTo: null,
            filters: {},
            workshopType: null,
            selectOptions: [
                {
                    text: this.$t('Upcoming sprints'),
                    value: 'upcoming',
                },
                {
                    text: this.$t('Past sprints'),
                    value: 'past',
                },
            ],
            errorFrom: false,
            errorTo: false,
        }
    },
    created() {
        this.selectedStatus = {
            text: this.$t('Upcoming sprints'),
            value: 'upcoming',
        }
    },
    methods: {
        changeFilters(status) {
            this.filters.page = 1
            const eventDateFrom = new Date(this.dateFrom)
            const mmFrom = eventDateFrom.getMonth() + 1
            const ddFrom = eventDateFrom.getDate()
            const yyFrom = eventDateFrom.getFullYear()
            this.filters.event_date_from = this.dateFrom
                ? `${yyFrom}-${mmFrom}-${ddFrom}`
                : ''

            const eventDateTo = new Date(this.dateTo)
            const mmTo = eventDateTo.getMonth() + 1
            const ddTo = eventDateTo.getDate()
            const yyTo = eventDateTo.getFullYear()
            this.filters.event_date_to = this.dateTo
                ? `${yyTo}-${mmTo}-${ddTo}`
                : ''

            const today = new Date()
            const mmToday = today.getMonth() + 1
            const ddToday = today.getDate()
            const yyToday = today.getFullYear()
            const dateToday = `${yyToday}-${mmToday}-${ddToday}`

            if (status) {
                if (status == 'past') {
                    this.filters.event_date_to = dateToday
                    this.filters.event_date_from = ''
                    this.dateTo = dateToday
                    this.dateFrom = ''
                    this.filters.ordering = '-event_date'
                    delete this.filters.event_datetime_from
                    this.filters.event_datetime_to = `${dateToday} ${today.getHours()}:${today.getMinutes()}`
                } else if (status == 'upcoming') {
                    this.filters.event_datetime_from = `${dateToday} ${today.getHours()}:${today.getMinutes()}`
                    delete this.filters.event_datetime_to
                    this.filters.event_date_from = dateToday
                    this.filters.event_date_to = ''
                    this.dateTo = ''
                    this.dateFrom = dateToday
                    this.filters.ordering = 'event_date'
                } else {
                    this.filters.ordering = 'event_date'
                    delete this.filters.event_datetime_from
                    delete this.filters.event_datetime_to
                    this.selectedStatus = {
                        text: this.$t('Nothing selected'),
                        value: '',
                    }
                }
            }

            this.filters.status = this.selectedStatus
                ? this.selectedStatus.text
                : ''

            if (status !== '1970-1-1') this.$emit('input', this.filters)
        },
        checkFrom(value) {
            let status = value
            if (!this.dateFrom || !this.dateTo) {
                this.errorFrom = false
                this.errorTo = false
                this.changeFilters(status)
            } else {
                let newDateFrom = new Date(this.dateFrom)
                let newDateTo = new Date(this.dateTo)
                if (newDateFrom < newDateTo) {
                    this.errorFrom = false
                    this.errorTo = false
                    this.changeFilters(status)
                } else {
                    this.errorFrom = true
                    this.selectedStatus = {
                        text: this.$t('Nothing selected'),
                        value: '',
                    }
                    this.$emit('resetResults')
                }
            }
        },
        checkTo(value) {
            let status = value
            if (!this.dateFrom || !this.dateTo) {
                this.errorTo = false
                this.errorFrom = false
                this.changeFilters(status)
            } else {
                let newDateFrom = new Date(this.dateFrom)
                let newDateTo = new Date(this.dateTo)
                if (newDateFrom <= newDateTo) {
                    this.errorTo = false
                    this.errorFrom = false
                    this.changeFilters(status)
                } else {
                    this.errorTo = true
                    this.selectedStatus = {
                        text: this.$t('Nothing selected'),
                        value: '',
                    }
                    this.$emit('resetResults')
                }
            }
        },
    },
    watch: {
        selectedStatus: function(value) {
            if (value && value.value != '') {
                this.changeFilters(value.value)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.filters {
    > div {
        flex: auto;
        max-width: none;
    }
    /deep/.vdp-datepicker {
        .form-control {
            height: 98px !important;
        }
    }

    .form-group {
        .control-label {
            padding-left: 3px;
            padding-bottom: rem(30px);
        }
        /deep/ .v-select-toggle {
            padding: rem(10px) rem(60px) rem(10px) rem(40px) !important;
            height: 98.89px;
            display: flex;
            align-items: center;
            color: #4d4d4d !important;
            font-size: rem(20px) !important;
            box-shadow: 0px 0px 12px rgb(214, 214, 214);
            &:focus,
            &:active {
                color: #fff !important;
            }
            .arrow-down {
                right: rem(32px);
                zoom: 0.9;
            }
            // @media screen and (min-width: 1025px) {
            //     &:hover {
            //         color: #fff !important;
            //     }
            // }
        }
        @media screen and (max-width: 767px) {
            /deep/ .v-select-toggle {
                height: 65px !important;
                .arrow-down {
                    zoom: 0.8;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        /deep/.vdp-datepicker {
            .form-control {
                height: 65px !important;
            }
        }
    }
}

.error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 1rem;
    color: red !important;
}

.ar {
    .filters {
        .form-group {
            .control-label {
                padding-right: 3px;
                padding-left: 0;
            }
            /deep/ .v-select-toggle {
                .arrow-down {
                    left: rem(32px);
                    right: auto;
                }
            }
        }
    }
}
</style>
