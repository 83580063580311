<template>
    <div v-if="workshops" class="row">
        <div
            v-for="item in workshops"
            :key="item.uuid"
            class="workshops__slide col-lg-6 col-sm-6"
        >
            <div class="workshops__slide--image">
                <img
                    v-if="item.image"
                    loading="lazy"
                    :src="item.image.file"
                    alt=""
                />
            </div>
            <div class="workshops__slide--desc">
                <div class="workshops__slide--desc--wrapper">
                    <p>
                        <a
                            :href="
                                '/' +
                                    $i18n.locale +
                                    '/sprints/details/' +
                                    item.uuid
                            "
                            >{{ item[`title_${$i18n.locale}`] }}</a
                        >
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-6 col-6-custom">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="fas fa-calendar-alt"></i>
                                </div>
                                {{ item.event_date | dateDay }}
                                {{ item.event_date | dateMonthYear }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-12">
                        <a
                            :href="
                                '/' +
                                    $i18n.locale +
                                    '/sprints/details/' +
                                    item.uuid
                            "
                            class="btn btn-primary"
                            >{{ $t('Read More') }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { speakers } from '@/modules/workshop/mixins/speakers.js'
export default {
    mixins: [speakers],
    name: 'workshops',
    components: {},
    props: {
        workshops: Array,
    },
    data() {
        return {}
    },
    methods: {},
    computed: {},
}
</script>
<style lang="scss" scoped>
.workshops {
    $self: &;
    position: relative;
    /deep/.greyBg {
        height: 400px;
        width: 500px;
        top: rem(18px);
        @media screen and (max-width: 767px) {
            top: rem(-10px);
        }
    }
    &__thumbSlider {
        margin: rem(60px) 0 rem(60px) 0;
        &--date {
            color: var(--primary);
            font-size: rem(32px);
            font-weight: 900;
            border: 1px solid #c0aecb;
            background-color: rgba(85, 85, 131, 0.1);
            @include flex(center, center);
            padding: rem(28px) rem(10px);
            min-width: 285px;
        }
        /deep/.slick-slide {
            padding-right: rem(35px);
            &.slick-current {
                #{$self}__thumbSlider--date {
                    background-color: var(--pink);
                    color: var(--white);
                }
            }
        }
    }

    &__slide {
        text-align: center;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        // width: 260px;
        &--image {
            position: relative;
            //border: solid 2px #009b74;
            // @include flex(center,center);
            height: 300px;
            img {
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &--desc {
            margin-top: 0;
            // border: solid 1px #009b74;
            box-shadow: 0 0 12px #d6d6d6;
            background: white;
            padding: 10px;
            padding-bottom: rem(30px);
            flex: 1;
            .btn {
                margin-top: 10px;
                &:hover {
                    background-color: var(--primary) !important;
                }
            }
            p {
                font-size: rem(28px);
                font-weight: 600;
                text-align: left;
                // background: linear-gradient(179deg, #009b74 0%, #009b74 100%);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                padding-bottom: rem(15px);
                margin-bottom: rem(20px);
                line-height: 1.3;
                position: relative;
                a {
                    background: linear-gradient(
                        179deg,
                        #009b74 0%,
                        var(--green) 100%
                    );
                    &:hover {
                        background: transparent !important;
                        color: var(--green) !important;
                        -webkit-background-clip: initial !important;
                        -webkit-text-fill-color: initial !important;
                        text-decoration: underline;
                    }
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    @media screen and (min-width: 1025px) {
                        &:hover {
                            background: var(--secondary);
                            color: var(--secondary);
                            background-clip: text;
                            -webkit-text-fill-color: transparent;
                            -webkit-background-clip: text;
                        }
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    width: 40px;
                    height: 1px;
                    background: var(--primary);
                    left: 0;
                    bottom: 0;
                }
            }
            ul {
                list-style: none;
                margin-bottom: 5px;
                li {
                    font-size: rem(24px);
                    font-weight: 600;
                    color: #e4d700;
                    line-height: 2;
                    display: flex;
                    text-align: left;
                    .icon {
                        margin-right: rem(20px);
                        color: var(--primary);
                        svg {
                            width: 25px;
                            height: auto;
                        }
                        img {
                            width: 25px;
                            height: auto;
                            display: inline;
                        }
                    }
                }
            }
            &--wrapper {
                min-height: 105px;
            }
        }
        &--playIcon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: solid 4px #fff;
            position: absolute;
            @include center-both();
            color: #fff;
            @include flex(center, center);
            cursor: pointer;
            svg {
                width: 15px;
                height: auto;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    color: var(--secondary);
                    border-color: var(--secondary);
                }
            }
        }
    }
    &__nav {
        position: relative;
        width: 260px;
        ul {
            justify-content: space-between;
            align-items: center;
            li {
                &.counter {
                    font-size: rem(32px);
                    color: var(--primary);
                    font-weight: 700;
                }
            }
        }
        button {
            background: transparent;
            border: 0px;
            color: #009b74;
            padding: 0;
            svg {
                width: rem(55px);
                height: auto;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    color: #009b74;
                }
            }
        }
        &--previous {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    &__title {
        font-size: rem(52px);
        font-weight: 900;
        color: var(--primary);
        text-transform: uppercase;
    }
    @media screen and (max-width: 1199px) {
        &__thumbSlider {
            &--date {
                min-width: 250px;
            }
        }
        &__nav {
            width: 210px;
        }
        &__title {
            font-size: rem(35px);
        }
    }
    @media screen and (max-width: 767px) {
        &__slide {
            &--desc {
                p {
                    font-size: rem(24px);
                }
                ul {
                    li {
                        margin-bottom: rem(6px);
                        .icon {
                            width: 20px;
                        }
                    }
                }
            }
        }
        &__nav {
            ul {
                li {
                    &.counter {
                        font-size: rem(24px);
                    }
                }
            }
            button {
                svg {
                    width: rem(37px);
                }
            }
        }
        &__thmubSlider {
            .slick-slide {
                padding: 0 10px;
            }
        }
    }
    @media screen and (max-width: 575px) {
        .d-flex {
            flex-direction: column;
        }
        &__nav {
            margin-top: rem(30px);
        }
        &__thumbSlider {
            &--date {
                font-size: rem(25px);
                min-width: 200px;
            }
        }
        &__slide {
            &--desc {
                ul {
                    li {
                        font-size: rem(17px);
                        margin-bottom: rem(20px);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 414px) {
        &__title {
            font-size: rem(26px);
        }
        &__thumbSlider {
            /deep/.slick-slide {
                padding: 0 20px;
            }
        }
    }
}

// ar style

.ar {
    .workshops {
        $self: &;
        &__slide {
            &--desc {
                p {
                    text-align: right;
                    &:after {
                        right: 0;
                        left: auto;
                    }
                }
                ul {
                    li {
                        text-align: right;
                        direction: ltr;
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;
                        .icon {
                            margin-left: rem(20px);
                            margin-right: 0;
                        }
                    }
                }
                .row {
                    justify-content: flex-start;
                    direction: rtl;
                }
            }
        }
        &__nav {
            button {
                svg {
                    transform: rotate(180deg);
                }
            }
            ul {
                li.counter {
                    direction: ltr;
                }
            }
        }
    }
    .workshops__nav--previous svg {
        transform: rotate(0deg) !important;
    }
}
.col-6-custom {
    padding-left: 14px;
    padding-right: 14px;
}
</style>
